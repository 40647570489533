@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.dark a,
.light a {
    color: #96a9ff;
    /* Define a cor do texto para branco */
    text-decoration: none;
    /* Remove o sublinhado padrão dos links */
    margin-left: 5px;
    /* Adiciona uma margem à esquerda para separar os links */
}

.dark a:hover,
.light a:hover {
    color: #b2c0fc;
    /* Define a cor do texto para branco */
    text-decoration: none;
    /* Remove o sublinhado padrão dos links */
    margin-left: 5px;
    /* Adiciona uma margem à esquerda para separar os links */
}

@media screen and (min-width: 950px) {
    .dark {
        position: flex;
        bottom: 0;
        height: auto;
        max-height: 100px;
        width: 100%;
        max-width: 100%;
        text-align: center;
        font-weight: bold;
        line-height: 1.5;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 15px;
        color: rgb(255, 255, 255);
        padding-top: 1%;
        background-color: rgb(17, 17, 19);
        padding-bottom: 1%;
        opacity: 0;
        animation: fadeInUp 0.9s ease-in-out 0.4s forwards;



    }

    .light {


        bottom: 0;
        height: auto;
        max-height: 100px;
        width: 100%;
        max-width: 100%;
        text-align: center;
        font-weight: bold;
        line-height: 1.5;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 15px;
        color: rgb(167, 167, 167);
        padding-top: 1%;
        background: #1f1f24;
        padding-bottom: 1%;
        opacity: 0;
        animation: fadeInUp 0.9s ease-in-out 0.4s forwards;

    }
}


@media screen and (max-width: 950px) {
    .dark {
        position: flex;
        bottom: 0;
        height: auto;
        max-height: 100px;
        width: 100%;
        max-width: 100%;
        text-align: center;
        font-weight: bold;
        line-height: 1.5;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 3vw;
        color: rgb(167, 167, 167);
        padding-top: 1%;
        background-color: rgb(17, 17, 19);
        padding-bottom: 2%;

    }

    .light {

        position: flex;
        bottom: 0;
        height: auto;
        max-height: 100px;
        width: 100%;
        max-width: 100%;
        text-align: center;
        font-weight: bold;
        line-height: 1.5;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 3vw;
        color: rgb(216, 216, 216);
        background: #1f1f24;
        padding-bottom: 1%;
        padding-top: 3%;



    }

}