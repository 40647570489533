@media screen and (min-width: 1050px) {}

.light {

    .tabsContainer {
        display: flex;
        justify-content: center;
        /* Centraliza os elementos horizontalmente */
        align-items: center;
        /* Centraliza os elementos verticalmente */
        background: linear-gradient(to right, #ffffff, #ffffff);
        height: 100px;
        box-sizing: border-box;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-bottom: 0.1px solid rgb(45, 45, 45);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1001;


    }

    .MenuContainer {
        padding-top: 40px;
        padding-bottom: 40px;
        font-size: 10px;
    }


    .LogoContainer {

        padding: 0;
        margin: 0;
        margin-right: 3%;
        margin-left: 5%;

    }

    .LogoContainer h2 {
        margin: 0;
        margin-bottom: -5px;
        margin-top: 10px;
        font-size: 15px;
        margin-left: -2%;

    }

    .LogoContainer .psico {
        display: inline;
        color: #A6A6A6;
        margin-right: 1px;
        line-height: 2.5;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        margin-left: 0;


    }

    .LogoContainer .flex {
        display: inline;
        color: #F2C630;
        margin-top: 10px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

        /* Ajusta a altura da linha */
    }

    .Logo {
        display: block;
        width: 200px;
        height: 100px;
        margin: 0;
        padding: 0;




    }




    .ClienteContainer {
        margin-left: auto;
        padding-top: 40px;
        padding-bottom: 40px;
        font-size: 10px;
    }

    .iconProfile {
        vertical-align: top;
        color: rgb(0, 0, 0);
        padding-right: 5px;
        /* Alinha verticalmente o ícone */
    }


    .themeButton {
        margin-left: 3%;
        margin-right: 5%;
    }

    .iconEscuro {
        color: rgb(0, 0, 0);
        font-size: 30px;
        /* ou qualquer valor desejado */

    }


    .tab {
        font-family: Arial, sans-serif;
        font-size: 1.2vw;
        text-decoration: none;
        font-weight: bold;
        border-radius: 20px;
        color: rgb(138, 138, 138);
        padding: 15px;
        padding-left: 10px;

        transition: background-color 0.3s, color 0.3s;


        @media screen and (max-width: 1250px) {
            padding-left: 0;
            font-size: 1.15vw;

        }

    }




    .tab:not(:last-child),
    .tab1:not(:last-child) {
        margin-right: 20px;
    }

    .tab:hover,
    .tab1:hover,
    .tabsContainer button:hover {
        background-color: #2a292e;
        color: #ffffff;

    }

    .tabsContainer button {
        border: none;
        border-radius: 10px;
        color: rgb(138, 138, 138);
        background-color: transparent;
        transition: background-color 0.3s, color 0.3s;
        font-size: 14px;
        font-family: arial;
    }

    .topTab button {
        display: none;
    }


    .tab:not(:last-child),
    .tab1:not(:last-child) {
        margin-right: 20px;
    }

    .tab:hover,
    .tab1:hover,
    .tabsContainer button:hover {
        background-color: #d6d6d6;
        color: #0170c4;


    }

    .tabsContainer button {
        border: none;
        border-radius: 10px;
        color: rgb(66, 66, 66);
        background-color: transparent;
        font-size: 15px;
        font-family: arial;
        cursor: pointer;
    }

    .topTab button {
        border: none;
        border-radius: 10px;
        color: rgb(0, 0, 0);
        background-color: transparent;

    }

    .closeButton {
        display: none;
    }






}

.dark {


    .tabsContainer {
        display: flex;
        justify-content: center;
        /* Centraliza os elementos horizontalmente */
        align-items: center;
        /* Centraliza os elementos verticalmente */
        background: linear-gradient(to right, #040403, #161613);
        height: 100px;
        box-sizing: border-box;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-bottom: 0.1px solid rgb(45, 45, 45);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1001;

    }

    .MenuContainer {
        padding-top: 40px;
        padding-bottom: 40px;
        font-size: 10px;
    }


    .LogoContainer {

        padding: 0;
        margin: 0;
        margin-right: 3%;
        margin-left: 5%;

    }

    .LogoContainer h2 {
        margin: 0;
        margin-bottom: -5px;
        margin-top: 10px;
        color: white;
        font-size: 15px;
        margin-left: -2%;

    }

    .LogoContainer .psico {
        display: inline;
        color: #A6A6A6;
        margin-right: 1px;
        line-height: 2.5;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        margin-left: 0;


    }

    .LogoContainer .flex {
        display: inline;
        color: #F2C630;
        margin-top: 10px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

        /* Ajusta a altura da linha */
    }

    .Logo {
        display: block;
        width: 200px;
        height: 100px;
        margin: 0;
        padding: 0;




    }




    .ClienteContainer {
        margin-left: auto;
        padding-top: 40px;
        padding-bottom: 40px;
        font-size: 10px;
    }

    .iconProfile {
        vertical-align: top;
        color: white;
        padding-right: 5px;
        /* Alinha verticalmente o ícone */
    }

    .iconLogin {
        padding-right: 10px;
    }


    .themeButton {
        margin-left: 3%;
        margin-right: 5%;
    }

    .iconClaro {
        color: rgb(255, 255, 255);
        font-size: 30px;
        /* ou qualquer valor desejado */

    }


    .tab,
    .tab1 {
        font-family: Arial, sans-serif;
        font-size: 1.2vw;
        text-decoration: none;
        font-weight: bold;
        border-radius: 10px;
        color: rgb(138, 138, 138);
        padding: 15px;
        padding-left: 5px;


        transition: background-color 0.3s, color 0.3s;


        @media screen and (max-width: 1250px) {
            padding-left: 0;
            font-size: 1.15vw;

        }

    }



    .tab:not(:last-child),
    .tab1:not(:last-child) {
        margin-right: 20px;
    }

    .tab:hover,
    .tab1:hover,
    .tabsContainer button:hover {
        background-color: #2a292e;
        color: #ffffff;

    }

    .tabsContainer button {
        border: none;
        border-radius: 10px;
        color: rgb(138, 138, 138);
        background-color: transparent;
        transition: background-color 0.3s, color 0.3s;
        font-size: 14px;
        font-family: arial;



    }

    .topTab button {
        display: none;
    }

    .topTab button:hover {
        color: #00a3c4;
    }

    .closeButton {
        display: none;
    }


}

@media screen and (max-width: 1050px) {
    .dark {


        .topTab {
            background: rgb(0, 0, 0);
            border-top: 1px solid #001e53;
            color: #5b5a63;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 100%;
            z-index: 1001;
            height: 30px;
            text-align: center;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
            margin-bottom: 0;
        }



        .topTab button {
            display: flex;
            border: none;
            border-radius: 10px;
            color: rgb(115, 115, 115);
            background-color: transparent;
            margin-right: 4%;
            font-size: 15px;
            padding-right: 2%;
            width: auto;
            max-width: auto;
            align-items: center;
        }

        .iconClaro {
            color: rgb(255, 255, 255);
            margin-left: 5px;
            /* ou qualquer valor desejado */
            font-size: 20px;
        }

        .menuIcon {
            cursor: pointer;
        }

        .menuIcon div {
            width: 25px;
            height: 5px;
            background-color: #797979;
            margin: 6px 0;
            transition: background-color 0.3s;
        }

        .menuIcon:hover div {
            background-color: #00a3c4;
        }

        .menuIcon.active div {
            background-color: #00aed1;
        }

        .iconActive {
            color: #00aed1;
        }

        .tabsContainer {
            display: grid;
            grid-template-columns: 1fr;
            background: linear-gradient(to right, #040403, #161613);
            height: 100%;
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-bottom: 0.1px solid rgb(45, 45, 45);
            top: 0;
            left: -1000px;
            width: 300px;
            z-index: 1002;


        }

        /* Ajustes para os itens dentro da lista */
        .tabsContainer>* {
            padding: 0;
            margin: 0;
            border-bottom: 1px solid #333;

        }

        /* Estilizando os links dentro da lista */
        .tabsContainer a {
            color: #fff;
            text-decoration: none;
            font-size: 16px;
            display: block;
            margin-left: 20px;
            padding-bottom: 5%;
            padding-top: 5%;

        }

        /* Adicionando estilo ao último item da lista */
        .tabsContainer> :last-child {
            border-bottom: none;
        }

        .tab1 {
            border: none;
        }

        .open {
            left: 0;
        }



        /* Centralizar LogoContainer */
        .LogoContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .TextContainer {
            display: inline-block;
            margin: 0;
            padding: 0;

        }



        .Logo {
            display: block;
            margin-top: 0;
            /* Adiciona espaço acima da imagem */
            margin-bottom: 10px;
            /* Adiciona espaço abaixo da imagem */
            margin-right: 20px;
        }



        .MenuContainer,
        .ClienteContainer {
            margin: 0;
            height: auto;
            width: 100%;
            padding-bottom: 20%;

        }

        .AlinharBotao {
            display: flex;
            /* Use flexbox para alinhar os itens */
            justify-content: flex-start;
            /* Alinhe os itens à esquerda */
            border: none;
            margin-bottom: 0;

        }

        .closeButton {
            display: block;
            width: 20%;
            margin-left: 85%;

        }

        .closeButton p {
            margin: 0;
            padding: 0;
            font-size: 25px;
            color: white;


        }

        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            /* Cor de fundo semi-transparente */
            z-index: 999;
            /* Garante que a camada esteja acima do conteúdo principal */
        }



    }

    .light {

        .topTab {
            background: rgb(255, 255, 255);
            border-top: 1px solid #001e53;
            color: #5b5a63;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 100%;
            z-index: 1002;
            height: 30px;
            text-align: center;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
            margin-bottom: 0;
        }



        .topTab button {
            display: flex;
            border: none;
            border-radius: 10px;
            color: rgb(86, 86, 86);
            background-color: transparent;
            margin-right: 4%;
            font-size: 15px;
            padding-right: 2%;
            width: auto;
            max-width: auto;
            align-items: center;
            cursor: pointer;
        }

        .topTab button:hover {
            color: rgb(20, 13, 95);
            background-color: rgb(227, 227, 227);

        }

        .iconClaro {
            margin-left: 5px;
            /* ou qualquer valor desejado */
            font-size: 20px;
        }

        .menuIcon {
            cursor: pointer;
        }

        .menuIcon div {
            width: 25px;
            height: 5px;
            background-color: #5c5c5c;
            margin: 6px 0;
            transition: background-color 0.3s;
        }

        .menuIcon:hover div {
            background-color: #00a3c4;
        }

        .menuIcon.active div {
            background-color: #00aed1;
        }

        .iconActive {
            color: #00aed1;
        }

        .topTab button:hover {
            color: #038ef8;
        }

        .tabsContainer {
            display: grid;
            grid-template-columns: 1fr;
            background: rgb(240, 240, 240);
            height: 100%;
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-bottom: 0.1px solid rgb(45, 45, 45);
            top: 0;
            left: -1000px;
            width: 300px;
            z-index: 1002;



        }

        /* Ajustes para os itens dentro da lista */
        .tabsContainer>* {
            margin: 0;
            border-bottom: 1px solid #858585;



        }

        /* Estilizando os links dentro da lista */
        .tabsContainer a {
            color: #595959;
            text-decoration: none;
            font-size: 16px;
            display: block;
            margin-left: 20px;
            padding-bottom: 5%;
            padding-top: 5%;

        }

        /* Adicionando estilo ao último item da lista */
        .tabsContainer> :last-child {
            border-bottom: none;
        }

        .tab1 {
            border: none;
        }

        .open {
            left: 0;
        }



        /* Centralizar LogoContainer */
        .LogoContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .TextContainer {
            display: inline-block;
            margin: 0;
            padding: 0;

        }



        .Logo {
            display: block;
            margin-top: 0;
            /* Adiciona espaço acima da imagem */
            margin-bottom: 10px;
            /* Adiciona espaço abaixo da imagem */
            margin-right: 20px;
        }



        .MenuContainer {
            padding-top: -500px;
        }

        .ClienteContainer {
            padding-bottom: 500px;
        }

        .AlinharBotao {
            display: flex;
            /* Use flexbox para alinhar os itens */
            justify-content: flex-start;
            /* Alinhe os itens à esquerda */
            border: none;


        }

        .closeButton {
            display: block;
            width: 15%;
            margin-left: 85%;
            margin-top: 15px;



        }


        .closeButton p {
            margin: 0;
            padding: 0;
            font-size: 25px;
            color: rgb(0, 0, 0);
        }

        .closeButton p:hover {
            color: rgb(0, 0, 0);
        }

        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            /* Cor de fundo semi-transparente */
            z-index: 999;
            /* Garante que a camada esteja acima do conteúdo principal */
        }



    }

}

@media screen and (max-width: 300px) {

    .light {
        .tabsContainer {
            width: 100%;
        }

        .tabsContainer a {
            font-size: 6vw;
        }

    }

    .dark {
        .tabsContainer {
            width: 100%;
        }


    }

}