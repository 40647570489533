@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


.dark,
.light {
    background-color: rgb(17, 17, 19);
    display: flex;
    color: white;
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    opacity: 0;
    animation: fadeInUp 0.9s ease-in-out 0.4s forwards;





    .containerContato {
        width: 100%;
        max-width: 100%;
        margin-top: 0;
        padding-top: 20px;


    }

    .containerContato h1 {
        margin: 0;
        padding: 0;
        font-size: 25px;
        margin-left: 25%;
        margin-bottom: 20px;

        @media screen and (max-width: 1150px) {
            margin-left: 10%;
        }

    }

    .containerContato li {
        list-style-type: none;
        /* Remove os pontos da lista */
    }






    .contactItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 0;
        margin-left: 25%;

        @media screen and (max-width: 1150px) {
            margin-left: 10%;
        }


        /* Define a largura máxima para evitar que ocupe toda a largura */
    }

    .contactItem a {

        font-size: 15px;
        /* Ajuste conforme necessário */
        margin: 0;
        padding: 0;
        color: #8ab4f8;
        cursor: pointer;
        font-weight: bold;
        padding: 0;
        margin: 0;
        text-decoration: none;






    }



    .whatsappIcon {
        font-size: 30px;
        color: green;
        vertical-align: middle;
        padding-left: 10px;
        padding-bottom: 5px;
        padding-right: 10px;

    }


    .gmailIcon {

        font-size: 30px;
        color: rgb(160, 18, 18);
        vertical-align: middle;
        padding-left: 10px;


    }


    .contactItem a:hover {
        color: #b5dbff;
        /* Cor sólida */
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
        /* Adicionei a transição da cor */
    }





    .containerExtra {
        width: 50%;
        max-width: 50%;
        font-size: 15px;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        padding-right: 10%;


    }

    .containerExtra h1 {
        margin: 0;
        padding: 0;
        font-size: 25px;


    }

    .containerExtra p {
        margin: 0;
        padding-top: 20px;
        line-height: 1.8;

    }

    .littleicons {
        vertical-align: middle;
        margin: 2%;
        /* Ajuste conforme você preferir */
    }



    /* Estilos para telas maiores */


    @media screen and (max-width: 700px) {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        text-align: left;
        margin: 0;
        padding: 0;
        padding-bottom: 50px;

        .containerContato {
            width: 80%;
            max-width: 80%;
            margin-left: 10%;

        }



        .containerContato h1 {

            margin: 0;
            padding: 0;
            margin-bottom: 10px;
        }

        .contactItem {
            margin: 0;
            padding: 0;

        }

        .contactItem a {
            margin: 0;
            padding: 0;
            transition: background-color 0.5s ease-in-out, transform 0.3s ease-in-out;

        }

        .contactItem a:hover {
            transition: background-color 0.5s ease-in-out, transform 0.3s ease-in-out;
        }

        .contactItem p {
            font-size: 15px;
            margin-bottom: 0;

        }

        .containerExtra {

            width: 80%;
            max-width: 80%;
            margin-left: 10%;
            font-size: 15px;
            padding: 0;


        }

        .containerExtra h1 {
            margin-bottom: 10px;
        }


    }

    @media screen and (max-width: 420px) {
        padding-bottom: 20px;

        .containerContato h1 {
            font-size: 20px;
            /* Reduza o tamanho da fonte */
        }

        .contactItem a {
            width: 90%;
            /* Reduza a largura dos links */
            padding: 2%;
            /* Adicione uma margem à esquerda */
        }

        .contactItem p {
            font-size: 3vw;
            text-align: left;
            /* Reduza o tamanho da fonte dos parágrafos */
        }

        .whatsappIcon {
            font-size: 4vw;
            /* Reduza o tamanho da fonte do icone */
        }

        .gmailIcon {
            font-size: 4vw;

            /* Reduza o tamanho da fonte do icone */
        }

        .containerExtra h1 {
            font-size: 6vw;
            max-width: 100%;
            margin: 0;
            padding: 0;
            /* Reduza o tamanho da fonte */

        }

        .containerExtra p {
            font-size: 3vw;
            max-width: 100%;
            margin: 0;

            /* Reduza o tamanho da fonte */
        }


    }
}

.light {
    background: #1f1f24;
    color: rgb(205, 205, 205);
}